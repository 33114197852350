/* IvyLeagueLogos.css */

.container {
  overflow: hidden;
  width: 100vw; /* Full viewport width */
  height: 120px; /* Adjust based on logo height */
  position: relative;

}

.animation-wrapper {
  display: flex;
  white-space: nowrap;
  position: absolute;
  top: 0;
  left: 0;
  width: calc(200%); /* Make sure it's double the viewport width for smooth looping */
  animation: scroll 10s linear infinite;
}

@keyframes scroll {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-50%);
  }
}

.logo {
  height: 100px; /* Adjust as needed */
  margin-right: 250px; /* Space between logos */
  filter: grayscale(100%);
}
