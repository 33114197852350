.about-container {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
    color: black;
}

.about-container h1 {
    font-size: 2.5rem;
    color: black;
}

.about-container h2 {
    font-size: 1.8rem;
    margin-top: 20px;
    color: black;
}

.about-container p {
    font-size: 1rem;
    line-height: 1.6;
    color: black;
}

.about-container ul {
    list-style-type: none;
    padding: 0;
}

.about-container ul li {
    font-size: 1rem;
    margin-bottom: 10px;
    color: black;
}
